import { BugReport, OpenInNew, RestartAlt } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { ReactComponent as ErrorSvg } from "../assets/error.svg";
import logo from "../assets/logo-color-outlined.png";

const ErrorFallback = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        gap: "1rem",
        margin: "2rem",
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <ErrorSvg height="30%" preserveAspectRatio="meet" />
      <img
        src={logo}
        alt="CrossOut Calendar"
        className="li-logo"
        style={{ width: "5rem" }}
      />
      <h2>You have encountered a problem.</h2>
      <p>
        We have been automatically notified and the work for the fix will start
        immediately.
      </p>
      <Button
        variant="contained"
        onClick={() => window.top?.location.reload()}
        startIcon={<RestartAlt />}
      >
        Reload
      </Button>
      <div style={{ display: "flex", gap: "1rem" }}>
        <Button
          href="https://crossoutcalendar.statuspage.io/"
          startIcon={<OpenInNew />}
          target="_blank"
          variant="outlined"
          color="info"
        >
          Check status
        </Button>
        <Button
          href="https://crossoutcalendar.atlassian.net/servicedesk/customer/portals"
          startIcon={<BugReport />}
          target="_blank"
          variant="outlined"
          color="info"
        >
          Report a bug
        </Button>
      </div>
    </div>
  );
};

export default ErrorFallback;
