import { useContext } from "react";
import AuthContext from "./AuthContext";

const useAuth = () => {
  const { user, setCookieConsent } = useContext(AuthContext);

  const updateCookiePreference = (value: boolean) => {
    setCookieConsent && setCookieConsent(value);
  };

  return { user, updateCookiePreference };
};

export default useAuth;
