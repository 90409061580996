import { Button } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { signOut } from "../firebase/fbConfig";
import { useState } from "react";

import "../css/LoginBar.css";
import { useAuth } from "../infrastructure/AuthContext";

const LoginBar = () => {
  const { user } = useAuth();

  const [show, setShow] = useState(false);

  return (
    <div className="lb-container">
      {user && (
        <div className="lb-user">
          <div className="lb-user-img">
            <img
              src={user.photoURL || ""}
              alt="profile"
              onClick={() => setShow(!show)}
            />
          </div>
          {show && (
            <div className="lb-box-container" onClick={() => setShow(false)}>
              <div className="lb-user-box">
                <img
                  src={user.photoURL || ""}
                  alt="profile"
                  onClick={() => setShow(!show)}
                  style={{ borderRadius: "10rem", width: "5rem" }}
                />
                <div style={{ fontWeight: "700" }}>{user.displayName}</div>
                <div>{user.email}</div>
                <Button
                  variant="contained"
                  onClick={signOut}
                  startIcon={<ExitToApp />}
                  sx={{ color: "secondary.main", bgcolor: "white" }}
                >
                  Sign out
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LoginBar;
