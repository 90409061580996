const midnightDate = new Date(new Date().setHours(0, 0, 0, 0));

export const goalTemplate = {
  name: "New Goal",
  range: [
    midnightDate.toString(),
    new Date(
      midnightDate.getFullYear(),
      midnightDate.getMonth() + 1,
      midnightDate.getDate()
    ).toString(),
  ] as [string, string],
  selected: {} as { [year: number]: { [month: number]: number[] } },
  rule: {
    frequency: 0,
    interval: 1,
    weekPattern: [0, 1, 2, 3, 4, 5, 6],
    monthPattern: new Array(31).fill(undefined).map((_, i) => i + 1),
  },
};

export interface GoalType {
  name: string;
  range: [string, string];
  selected: { [year: number]: { [month: number]: number[] } };
  rule: {
    frequency: number;
    interval: number;
    weekPattern: number[];
    monthPattern: number[];
  };
}
