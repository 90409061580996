import logo from "../assets/logo-dark.png";
import { Button, Switch } from "@mui/material";
import Tutorial from "./Tutorial";
import { useState } from "react";

import "../css/Footer.css";
import { DarkMode, LightMode } from "@mui/icons-material";

const Footer = () => {
  const [show, setShow] = useState(false);

  const [toggle, setToggle] = useState(false);

  const handleDarkMode = () => {
    if (toggle) {
      document.body.style.setProperty("--background-darker", "hsl(0, 0%, 93%)");
      document.body.style.setProperty("--background-dark", "hsl(0, 0%, 98%)");
      document.body.style.setProperty("--background", "hsl(0, 0%, 100%)");
      document.body.style.setProperty("--text-main", "rgba(0,0,0,0.87)");
    } else {
      document.body.style.setProperty("--background-darker", "hsl(0, 0%, 5%)");
      document.body.style.setProperty("--background-dark", "hsl(0, 0%, 10%)");
      document.body.style.setProperty("--background", "hsl(0, 0%, 15%)");
      document.body.style.setProperty("--text-main", "rgba(255,255,255,0.87)");
    }
    setToggle(!toggle);
  };

  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-info">
          <img src={logo} alt="CrossOut Calendar" className="footer-logo" />
          <div>
            {`© ${new Date().getFullYear()} CrossOut Calendar. All rights reserved.`}
          </div>
        </div>
        <div className="footer-guide">
          <Button onClick={() => setShow(!show)} variant="outlined">
            Guide
          </Button>
        </div>
        <div className="footer-dark-switch">
          <LightMode />
          <Switch onChange={handleDarkMode} />
          <DarkMode />
        </div>
        <Button type="button" data-cc="show-preferencesModal" color="info">
          Manage cookie preferences
        </Button>
      </div>
      <p className="footer-version">{`v.${process.env.REACT_APP_VERSION?.slice(
        0,
        4
      )}`}</p>
      {show && <Tutorial setShow={setShow} />}
    </div>
  );
};

export default Footer;
