import React, { ReactNode, useEffect, useState } from "react";
import AuthContext from "./AuthContext";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/fbConfig";
import { setUser as setSentryUser } from "@sentry/react";

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [cookieConsent, setCookieConsent] = useState(false);

  onAuthStateChanged(auth, (user) => {
    setUser(user);

    setSentryUser(
      user
        ? {
            id: user.uid,
            email: (cookieConsent && user.email) || undefined,
          }
        : null
    );
  });

  useEffect(() => {
    setSentryUser(
      user
        ? {
            id: user.uid,
            email: (cookieConsent && user.email) || undefined,
          }
        : null
    );
  }, [cookieConsent, user]);

  return (
    <AuthContext.Provider value={{ user, setCookieConsent }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
