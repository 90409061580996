import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { GoalType, goalTemplate } from "../dataStructures";
import { useCalendars } from "../infrastructure/CalendarContext";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../firebase/fbConfig";
import { useAuth } from "../infrastructure/AuthContext";
import { v4 } from "uuid";

const AddGoal = () => {
  const { calendars, updateUserData, switchToCalendar, order } = useCalendars();
  const { user } = useAuth();

  const handleGoalAdd = () => {
    if (!calendars || !user) {
      return;
    }

    const ID = v4();
    const calendarsDoc = doc(firestore, "users", user.uid, "calendars", ID);
    const userDoc = doc(firestore, "users", user.uid);
    Promise.all([
      setDoc(calendarsDoc, {
        ...goalTemplate,
      } as GoalType),
      setDoc(userDoc, {
        calendarsOrder: [...order, ID],
      }),
    ]).then(
      () => updateUserData && updateUserData().then(() => switchToCalendar(ID))
    );
  };

  return (
    <div className="li-add">
      <IconButton onClick={handleGoalAdd} className="icon-btn">
        <Add />
      </IconButton>
    </div>
  );
};

export default AddGoal;
