import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { enGB } from "date-fns/locale";
import { createTheme, ThemeProvider } from "@mui/material";
import { AuthProvider } from "./infrastructure/AuthContext";
import { CalendarsProvider } from "./infrastructure/CalendarContext";
import * as Sentry from "@sentry/react";

export const themeOptions = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00cccc",
    },
    secondary: {
      main: "#ff5454",
    },
    info: {
      main: "#808080",
    },
  },
});

Sentry.init({
  dsn: "https://8d2dbd641129894386ec200826ccd268@o4507567675342848.ingest.de.sentry.io/4507567677112400",
  enabled: process.env.NODE_ENV === "production",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/crossoutcalendar\.app/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: process.env.REACT_APP_VERSION,
});

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
      <ThemeProvider theme={themeOptions}>
        <AuthProvider>
          <CalendarsProvider>
            <App />
          </CalendarsProvider>
        </AuthProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
