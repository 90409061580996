import Progress from "./Progress";

import "../css/Info.css";
import InfoSettings from "./InfoSettings";

const Info = () => {
  return (
    <div className="info-container">
      <Progress />
      <InfoSettings />
    </div>
  );
};

export default Info;
