import { ChangeEvent, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, Delete } from "@mui/icons-material";
import Prompt from "./Prompt";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import "../css/InfoSettings.css";
import Recurrence from "./Recurrence";
import { useCalendars } from "../infrastructure/CalendarContext";
import { useAuth } from "../infrastructure/AuthContext";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase/fbConfig";
import { GoalType } from "../dataStructures";

const InfoSettings = () => {
  const {
    currentCalendar,
    calendars,
    setCurrentCalendarData,
    updateUserData,
    order,
    activeTabId,
  } = useCalendars();
  const { user } = useAuth();

  const [show, setShow] = useState(false);

  const handleUpdateName = (event: ChangeEvent<HTMLInputElement>) => {
    if (!currentCalendar) {
      return;
    }

    setCurrentCalendarData({
      ...currentCalendar,
      name: event.target.value,
    });
  };

  const handleRangeUpdate = (range: DateRange<Date>) => {
    if (!currentCalendar) {
      return;
    }

    const start = range[0];
    const end = range[1];

    if (!start || !end) {
      return;
    }

    const parsedStart = Date.parse(start.toDateString());
    const parsedEnd = Date.parse(end.toDateString());

    if (isNaN(parsedEnd) || isNaN(parsedStart)) {
      return;
    }
    setCurrentCalendarData({
      ...currentCalendar,
      range: [start.toDateString(), end.toDateString()],
    });
  };

  const handleSave = () => {
    if (!user || !activeTabId) {
      return;
    }

    const calendarsDoc = doc(
      firestore,
      "users",
      user.uid,
      "calendars",
      activeTabId
    );
    setDoc(calendarsDoc, currentCalendar as GoalType).then(
      () => updateUserData && updateUserData()
    );
  };

  const handleMove = (direction: "left" | "right") => {
    if (!user || !activeTabId) {
      return;
    }

    const index = order.findIndex((id) => id === activeTabId);
    const docRef = doc(firestore, "users", user.uid);

    const temp = [...order];

    if (direction === "left") {
      if (index === 0) {
        return;
      }

      [temp[index], temp[index - 1]] = [temp[index - 1], temp[index]];

      updateDoc(docRef, {
        calendarsOrder: temp,
      }).then(() => updateUserData && updateUserData());
    }

    if (direction === "right") {
      if (index === order.length - 1) {
        return;
      }

      [temp[index], temp[index + 1]] = [temp[index + 1], temp[index]];

      updateDoc(docRef, {
        calendarsOrder: temp,
      }).then(() => updateUserData && updateUserData());
    }
  };

  return currentCalendar && calendars && activeTabId ? (
    <div className="info-settings" id="info-settings">
      <div style={{ fontWeight: "500", width: "100%" }}>Settings</div>
      <div className="info-left">
        <TextField
          required
          label="Name"
          value={currentCalendar.name}
          onChange={handleUpdateName}
          placeholder="Goal"
          className="info-input"
        />
        {currentCalendar.range && (
          <DateRangePicker
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} className="info-input" />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} className="info-input" />
              </>
            )}
            value={[
              new Date(currentCalendar.range[0]),
              new Date(currentCalendar.range[1]),
            ]}
            onChange={handleRangeUpdate}
            className="picker"
          />
        )}

        <Recurrence />
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleSave}
          title="Save"
        >
          Save
        </Button>
      </div>
      <div className="info-right">
        <IconButton
          title="Move Left"
          onClick={() => handleMove("left")}
          className="icon-btn"
          disabled={order.indexOf(activeTabId) === 0}
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          title="Move Right"
          onClick={() => handleMove("right")}
          className="icon-btn"
          disabled={order.indexOf(activeTabId) === order.length - 1}
        >
          <ChevronRight />
        </IconButton>
        <div>
          <IconButton
            aria-label="delete"
            title="Delete"
            onClick={() => setShow(!show)}
            className="icon-btn"
          >
            <Delete />
          </IconButton>
          {show && user && <Prompt setShow={setShow} />}
        </div>
      </div>
    </div>
  ) : null;
};

export default InfoSettings;
