import React, { ReactNode, useCallback, useEffect, useState } from "react";
import CalendarsContext, { CalendarsList } from "./CalendarsContext";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useAuth } from "../AuthContext";
import { firestore } from "../../firebase/fbConfig";
import { GoalType } from "../../dataStructures";

interface CalendarsProviderProps {
  children: ReactNode;
}

const CalendarsProvider = ({ children }: CalendarsProviderProps) => {
  const { user } = useAuth();

  const [data, setData] = useState<CalendarsList | null>(null);
  const [activeTabId, setActiveTabId] = useState<string | null>(null);
  const [currentCalendar, setCurrentCalendar] = useState<GoalType | null>(null);
  const [order, setOrder] = useState<string[]>([]);

  const updateUserData = useCallback(async () => {
    if (!user) {
      return;
    }

    const calendarCollectionRef = collection(
      firestore,
      "users",
      user.uid,
      "calendars"
    );
    const calendarCollectionSnap = await getDocs(calendarCollectionRef);
    const calendars: CalendarsList = {};
    calendarCollectionSnap.docs.forEach((doc) => {
      calendars[doc.id] = doc.data() as GoalType;
    });
    setData(calendars);

    const userDoc = doc(firestore, "users", user.uid);
    const userDocSnap = await getDoc(userDoc);

    if (userDocSnap.exists()) {
      const calendarsOrder = userDocSnap.data().calendarsOrder;
      setOrder(calendarsOrder);
      if (activeTabId === null) {
        setActiveTabId(calendarsOrder[0]);
      }
      return calendarsOrder[0];
    }
  }, [activeTabId, user]);

  useEffect(() => {
    updateUserData();
  }, [updateUserData]);

  useEffect(() => {
    if (!activeTabId) {
      return;
    }

    setCurrentCalendar(data ? data[activeTabId] : null);
  }, [activeTabId, data]);

  return (
    <CalendarsContext.Provider
      value={{
        calendars: data,
        currentCalendar,
        activeTabId,
        setActiveTabId,
        setCurrentCalendar,
        updateUserData,
        order,
      }}
    >
      {children}
    </CalendarsContext.Provider>
  );
};

export default CalendarsProvider;
