import "../css/App.css";
import LoggedIn from "./LoggedIn";
import LoggedOut from "./LoggedOut";
import Footer from "./Footer";
import { getAdditionalUserInfo } from "firebase/auth";
import { firestore, signInWithGoogle } from "../firebase/fbConfig";
import { collection, doc, setDoc } from "firebase/firestore";
import { GoalType, goalTemplate } from "../dataStructures";
import { useAuth } from "../infrastructure/AuthContext";
import { useCalendars } from "../infrastructure/CalendarContext";
import { ErrorBoundary } from "@sentry/react";
import ErrorFallback from "./ErrorFallback";
import { v4 } from "uuid";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { useEffect } from "react";

function App() {
  const { user, updateCookiePreference } = useAuth();
  const { updateUserData } = useCalendars();

  const handleSignIn = () => {
    signInWithGoogle()
      .then((credential) => {
        const isNew = getAdditionalUserInfo(credential)?.isNewUser;
        if (isNew) {
          const usersRef = collection(firestore, "users");
          const usersDoc = doc(usersRef, credential.user.uid);
          const ID = v4();
          const calendarsDoc = doc(
            firestore,
            "users",
            credential.user.uid,
            "calendars",
            ID
          );
          Promise.all([
            setDoc(usersDoc, {
              calendarsOrder: [ID],
            }),
            setDoc(calendarsDoc, { ...goalTemplate } as GoalType),
          ]).then(() => updateUserData && updateUserData());
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {},
      },
      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies",
              description: "Cookie modal description",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage Individual preferences",
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close modal",
              sections: [
                {
                  title: "Somebody said ... cookies?",
                  description: "I want one!",
                },
                {
                  title: "Strictly Necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of the website and cannot be disabled.",

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and Analytics",
                  description:
                    "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                },
              ],
            },
          },
        },
      },
      onChange: ({ cookie }) =>
        updateCookiePreference(cookie.categories.includes("analytics")),
    });
  }, [updateCookiePreference]);

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <div className="App">
        {user ? <LoggedIn /> : <LoggedOut handleSignIn={handleSignIn} />}
        <Footer />
      </div>
    </ErrorBoundary>
  );
}

export default App;
