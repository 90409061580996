// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBfC0IjJCAag4SJb4xyiPkl4He9tpNcc6k",
  authDomain: "crossout-calendar.firebaseapp.com",
  projectId: "crossout-calendar",
  storageBucket: "crossout-calendar.appspot.com",
  messagingSenderId: "63783426010",
  appId: "1:63783426010:web:c43e4e18d82bde149452e3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const firestore = getFirestore(app);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = async () => signInWithPopup(auth, provider);

export const signOut = () => auth.signOut();

export default app;
