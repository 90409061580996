import { Dispatch, SetStateAction, createContext } from "react";
import { GoalType } from "../../dataStructures";

export type CalendarsList = Record<string, GoalType>;

const CalendarsContext = createContext<{
  calendars: CalendarsList | null;
  currentCalendar: GoalType | null;
  activeTabId: string | null;
  setActiveTabId: Dispatch<SetStateAction<string | null>> | undefined;
  setCurrentCalendar: Dispatch<SetStateAction<GoalType | null>> | undefined;
  updateUserData: (() => Promise<string | void>) | undefined;
  order: string[];
}>({
  calendars: null,
  currentCalendar: null,
  activeTabId: null,
  setActiveTabId: undefined,
  setCurrentCalendar: undefined,
  updateUserData: undefined,
  order: [],
});

export default CalendarsContext;
