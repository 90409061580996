import { Button } from '@mui/material';

import '../css/Tutorial.css';

const Tutorial = ({ setShow }) => {
  return (
    <div className="tutorial-container">
      <div className="tutorial">
        <h2>Welcome to CrossOut Calendar!</h2>
        <div>
          You can use this web app to keep track of your goals. Just set a start
          and an end date to your goal and you will have all the calendars in
          that range in front of you. Then you can cross out any day that you
          have completed your goal.
          <br />
        </div>
        <h3>Guide</h3>
        <ul style={{ paddingLeft: '1.5rem' }}>
          <li>
            Login to Google using the "SIGN IN WITH GOOGLE" button to save your
            data.
          </li>
          <li>Add goals using the "+" button.</li>
          <li>You can change the name of your goal.</li>
          <li>Make sure to set the range for your goal.</li>
          <li>
            You can see the progress of your goal on the info bar. First
            progress bar is the number of passed days, and the second is the
            number of crossed-out days.
          </li>
          <li>
            If you want to delete a goal, do so by clicking on the trash can
            button.
          </li>
        </ul>
        <Button variant="outlined" onClick={() => setShow(false)}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default Tutorial;
