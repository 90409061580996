import { MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import "../css/Recurrence.css";
import { useCalendars } from "../infrastructure/CalendarContext";
import { ChangeEvent } from "react";

const ABBREVIATIONS = ["M", "T", "W", "T", "F", "S", "S"];

const Recurrence = () => {
  const { currentCalendar, setCurrentCalendarData } = useCalendars();

  const handleIntervalUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!value || !currentCalendar) {
      return;
    }

    const numericValue = parseInt(value, 10);
    if (numericValue < 1) {
      return;
    }

    setCurrentCalendarData({
      ...currentCalendar,
      rule: {
        ...currentCalendar.rule,
        interval: numericValue,
      },
    });
  };

  const handleFrequencyUpdate = (event: SelectChangeEvent) => {
    if (!currentCalendar) {
      return;
    }

    setCurrentCalendarData({
      ...currentCalendar,
      rule: {
        ...currentCalendar.rule,
        frequency: parseInt(event.target.value, 10),
      },
    });
  };

  const handleWeekPatternUpdate = (day: number) => {
    if (!currentCalendar) {
      return;
    }

    setCurrentCalendarData({
      ...currentCalendar,
      rule: {
        ...currentCalendar.rule,
        weekPattern: currentCalendar.rule.weekPattern.includes(day)
          ? currentCalendar.rule.weekPattern.filter((d) => d !== day).length
            ? currentCalendar.rule.weekPattern.filter((d) => d !== day)
            : currentCalendar.rule.weekPattern
          : [...currentCalendar.rule.weekPattern, day],
      },
    });
  };

  const handleMonthPattern = (day: number) => {
    if (!currentCalendar) {
      return;
    }

    setCurrentCalendarData({
      ...currentCalendar,
      rule: {
        ...currentCalendar.rule,
        monthPattern: currentCalendar.rule.monthPattern.includes(day)
          ? currentCalendar.rule.monthPattern.filter((d) => d !== day).length
            ? currentCalendar.rule.monthPattern.filter((d) => d !== day)
            : currentCalendar.rule.monthPattern
          : [...currentCalendar.rule.monthPattern, day],
      },
    });
  };

  if (!currentCalendar) {
    return null;
  }

  return (
    <div className="recurrence-container">
      <div className="recurrence-main">
        Repeat every
        <TextField
          type={"number"}
          inputProps={{ min: 1 }}
          value={currentCalendar.rule.interval}
          onChange={handleIntervalUpdate}
        />
        <Select
          //@ts-ignore
          value={currentCalendar.rule.frequency}
          onChange={handleFrequencyUpdate}
          size="small"
        >
          <MenuItem value={0}>day</MenuItem>
          <MenuItem value={1}>week</MenuItem>
          <MenuItem value={2}>month</MenuItem>
        </Select>
      </div>

      {currentCalendar.rule.frequency === 1 && (
        <ul className="recurrence-week">
          {ABBREVIATIONS.map((abb, index) => (
            <li
              onClick={() => handleWeekPatternUpdate(index)}
              key={index}
              className={
                currentCalendar.rule.weekPattern.includes(index)
                  ? "pattern-selected"
                  : ""
              }
            >
              <div>{abb}</div>
            </li>
          ))}
        </ul>
      )}

      {currentCalendar.rule.frequency === 2 && (
        <div>
          <ul className="recurrence-month">
            {new Array(31).fill(undefined).map((_, i) => (
              <li
                key={i}
                onClick={() => handleMonthPattern(i + 1)}
                className={
                  currentCalendar.rule.monthPattern.includes(i + 1)
                    ? "pattern-selected"
                    : ""
                }
              >
                {i + 1}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Recurrence;
