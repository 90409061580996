import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import "../css/PolkaBanner.css";
import image from "../assets/Logo.png";

const PolkaBanner = ({ isClosed, closeBanner }) => {
  return !isClosed ? (
    <div className="polka-container">
      <img src={image} alt="CoC V.2!" style={{ position: "relative" }} />
      <div>
        <div className="polka-title">CrossOut Calendar V.2 coming soon</div>
        <div className="polka-subtitle">
          With a new design and lots of new features!
        </div>
      </div>
      <IconButton
        size="small"
        style={{ color: "rgba(145, 153, 153, 1)" }}
        onClick={() => closeBanner(true)}
      >
        <Close />
      </IconButton>
    </div>
  ) : null;
};

export default PolkaBanner;
