import logo from "../assets/logo-color-outlined.png";
import Goal from "./Goal";
import "../css/LoggedIn.css";
import Tabs from "./Tabs";
import LoginBar from "./LoginBar";
import { useCalendars } from "../infrastructure/CalendarContext";

const LoggedIn = () => {
  const { calendars, currentCalendar, switchToCalendar, activeTabId } =
    useCalendars();

  const handleChangeGoal = (id: string) => {
    switchToCalendar(id);
  };

  return calendars ? (
    <div className="li-container">
      <div className="nav-bar">
        <div className="li-logo-container">
          <img src={logo} alt="CrossOut Calendar" className="li-logo" />
        </div>
        <Tabs
          goals={calendars}
          activeTabId={activeTabId}
          handleChangeGoal={handleChangeGoal}
        />
        <div className="nav-user">
          <LoginBar />
        </div>
      </div>
      <div className="li-content">
        <Goal goalData={currentCalendar} />
      </div>
    </div>
  ) : null;
};

export default LoggedIn;
