import { Button } from "@mui/material";
import { Google } from "@mui/icons-material";
import { useState } from "react";
//@ts-expect-error
import Calendar from "react-calendar";

import "../css/LoggedOut.css";
import logo from "../assets/logo-color.png";
import PolkaBanner from "./PolkaBanner";

interface LoggedOutProps {
  handleSignIn: () => void;
}

const LoggedOut = ({ handleSignIn }: LoggedOutProps) => {
  const [isBannerClosed, setIsBannerClosed] = useState(false);

  const initialState = [];

  for (let i = 0; i < Math.floor(Math.random() * 3) + 1; i++) {
    initialState.push(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        Math.floor(Math.random() * 32)
      ).toDateString()
    );
  }

  const [days, setDays] = useState(initialState);

  const tileClassName = ({ date }: any) => {
    const today = new Date().toDateString();

    let className = "tile";

    if (days.find((dDate) => dDate === date.toDateString())) {
      className += " selected";
    }
    if (date.toDateString() === today) {
      className += " today";
    }
    return className;
  };

  const monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="lo-container">
      <div className="lo-left">
        <img src={logo} alt="logo-color" style={{ width: "15%" }} />
        <h1 className="lo-title">CrossOut Calendar</h1>
        <div className="lo-subtitle">
          Keep track of your progress and your goals by crossing the days out of
          the calendar
        </div>
        <h3>Sign in to start:</h3>
        <div>
          <Button
            variant="contained"
            onClick={handleSignIn}
            startIcon={<Google />}
            color={"secondary"}
          >
            Sign in with Google
          </Button>
        </div>
      </div>
      <div className="lo-right">
        <div className="calendar lo-calendar">
          <div className="calendar-label">{`${
            monthLabels[new Date().getMonth()]
          } ${new Date().getFullYear()}`}</div>
          <Calendar
            activeStartDate={new Date()}
            showNavigation={false}
            showNeighboringMonth={false}
            onChange={(day: { toDateString: () => any }) => {
              const dayString = day.toDateString();
              days.includes(dayString)
                ? setDays(days.filter((d) => d !== dayString))
                : setDays([...days, dayString]);
            }}
            tileClassName={tileClassName}
          />
        </div>
      </div>
      <PolkaBanner isClosed={isBannerClosed} closeBanner={setIsBannerClosed} />
    </div>
  );
};

export default LoggedOut;
