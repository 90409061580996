import { useContext } from "react";
import CalendarsContext from "./CalendarsContext";
import { GoalType } from "../../dataStructures";

const useCalendars = () => {
  const {
    calendars,
    currentCalendar,
    setCurrentCalendar,
    activeTabId,
    setActiveTabId,
    updateUserData,
    order,
  } = useContext(CalendarsContext);

  const switchToCalendar = (id: string) => {
    setActiveTabId && setActiveTabId(id);
  };

  const setCurrentCalendarData = (calendar: GoalType) => {
    setCurrentCalendar && setCurrentCalendar(calendar);
  };

  return {
    calendars,
    currentCalendar,
    setCurrentCalendarData,
    switchToCalendar,
    updateUserData,
    order,
    activeTabId,
  };
};

export default useCalendars;
