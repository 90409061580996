//@ts-expect-error
import Calendar from "react-calendar";
import "../css/Calendars.css";
import { GoalType } from "../dataStructures";
import { calculateDays } from "../commonScripts";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../firebase/fbConfig";
import { useCalendars } from "../infrastructure/CalendarContext";
import { useAuth } from "../infrastructure/AuthContext";

interface CalendarsProps {
  goalData: GoalType;
}

const getCalendarMap = (startDate: Date, endDate: Date) => {
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();

  let calendarMap: { [year: number]: number[] } = {};
  for (let year = startYear; year <= endYear; year++) {
    calendarMap[year] = [];
    if (startYear === endYear) {
      for (let month = startMonth; month <= endMonth; month++) {
        calendarMap[year] = [...calendarMap[year], month];
      }
    } else {
      if (year === startYear) {
        for (let month = startMonth; month <= 11; month++) {
          calendarMap[year] = [...calendarMap[year], month];
        }
      } else if (year === endYear) {
        for (let month = 0; month <= endMonth; month++) {
          calendarMap[year] = [...calendarMap[year], month];
        }
      } else {
        for (let month = 0; month <= 11; month++) {
          calendarMap[year] = [...calendarMap[year], month];
        }
      }
    }
  }

  return calendarMap;
};

const Calendars = ({ goalData }: CalendarsProps) => {
  const { updateUserData, activeTabId } = useCalendars();

  const { user } = useAuth();

  const { range, selected, rule } = goalData;

  const [startDate, endDate] = [new Date(range[0]), new Date(range[1])];

  const calendarMap = getCalendarMap(startDate, endDate);
  const scheduleRule = calculateDays(rule, startDate, endDate);

  const availableDays = scheduleRule
    ? scheduleRule
        .occurrences()
        .toArray()
        .map(({ date }) => date.toString())
    : [];

  const tileClassName = ({ date }: { date: Date }) => {
    const selectedYear = selected && selected[date.getFullYear()];
    const selectedMonth = selectedYear && selectedYear[date.getMonth()];

    const today = new Date().toDateString();

    let className = "tile";

    if (
      selectedMonth &&
      selectedMonth.find((dDate) => dDate === date.getDate()) &&
      availableDays.includes(date.toString())
    ) {
      className += " selected";
    }
    if (date.toDateString() === today) {
      className += " today";
    }
    if (startDate && date.toDateString() === startDate.toDateString()) {
      className += " start-end";
    }
    if (endDate && date.toDateString() === endDate.toDateString()) {
      className += " start-end";
    }
    return className;
  };

  const monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDaySelect = async (day: Date) => {
    if (!user || !activeTabId) {
      return;
    }

    const year = day.getFullYear();
    const month = day.getMonth();
    const date = day.getDate();

    const currentCalendarWithNewData: GoalType = {
      ...goalData,
    };

    if (!currentCalendarWithNewData.selected[year]) {
      currentCalendarWithNewData.selected[year] = {};
    }

    if (!currentCalendarWithNewData.selected[year][month]) {
      currentCalendarWithNewData.selected[year][month] = [];
    }

    if (currentCalendarWithNewData.selected[year][month].includes(date)) {
      currentCalendarWithNewData.selected[year][month] =
        currentCalendarWithNewData.selected[year][month].filter(
          (d) => d !== date
        );
    } else {
      currentCalendarWithNewData.selected[year][month].push(date);
    }

    const calendarsDoc = doc(
      firestore,
      "users",
      user.uid,
      "calendars",
      activeTabId
    );
    setDoc(calendarsDoc, currentCalendarWithNewData as GoalType).then(
      () => updateUserData && updateUserData()
    );
  };

  const getTileDisabled = (tile: any) => {
    const isDisabled =
      tile.date < startDate ||
      tile.date > endDate ||
      !availableDays.includes(tile.date.toString());

    return isDisabled;
  };

  return (
    <div className="calendars-container">
      <div className="calendars">
        {Object.entries(calendarMap).map(([year, months]) =>
          months.map((month: any) => (
            <div key={year + "-" + month} className="calendar">
              <div className="calendar-label">{`${monthLabels[month]} ${year}`}</div>
              <Calendar
                activeStartDate={new Date(parseInt(year, 10), month, 1)}
                showNavigation={false}
                showNeighboringMonth={false}
                onChange={handleDaySelect}
                tileClassName={tileClassName}
                tileDisabled={getTileDisabled}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Calendars;
