import AddGoal from "./AddGoal";
import { Button } from "@mui/material";
import { useCalendars } from "../infrastructure/CalendarContext";
import { CalendarsList } from "../infrastructure/CalendarContext/CalendarsContext";

interface TabsProps {
  goals: CalendarsList;
  activeTabId: string | null;
  handleChangeGoal: (id: string) => void;
}

const Tabs = ({ goals, activeTabId, handleChangeGoal }: TabsProps) => {
  const { order } = useCalendars();
  return (
    <div className="tablist">
      {goals &&
        order.map((id) => {
          const calendar = goals[id];
          if (!calendar) {
            return null;
          }

          return (
            <Button
              key={id}
              className="tab"
              variant={id === activeTabId ? "contained" : "outlined"}
              onClick={() => handleChangeGoal(id)}
              color="primary"
            >
              {calendar.name}
            </Button>
          );
        })}
      <AddGoal />
      <div className="shadow-div"></div>
    </div>
  );
};

export default Tabs;
