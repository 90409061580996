import Info from "./Info";
import Calendars from "./Calendars";

import "../css/Goal.css";
import { IconButton } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useState } from "react";
import { GoalType } from "../dataStructures";

interface GoalProps {
  goalData: GoalType | null;
}

const Goal = ({ goalData }: GoalProps) => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    const infoDiv = document.getElementById("info-settings");
    if (!infoDiv) {
      return;
    }

    if (toggle) {
      infoDiv.style.animation = "slideUp 0.5s ease-in-out forwards";
    } else {
      infoDiv.style.animation = "slideDown 0.5s ease-in-out forwards";
    }

    setToggle(!toggle);
  };

  return goalData ? (
    <div className="goal-container">
      <Info />
      <div className="mobile-toggle">
        <IconButton onClick={handleToggle} className="icon-btn">
          {toggle ? <ArrowUpward /> : <ArrowDownward />}
        </IconButton>
      </div>
      <Calendars goalData={goalData} />
    </div>
  ) : null;
};

export default Goal;
