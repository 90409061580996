import { LinearProgress } from "@mui/material";

import "../css/Progress.css";
import { useCalendars } from "../infrastructure/CalendarContext";
import { calculateDays } from "../commonScripts";

const Progress = () => {
  const { currentCalendar } = useCalendars();

  if (!currentCalendar) {
    return null;
  }

  const start = new Date(currentCalendar.range[0]);
  const end = new Date(currentCalendar.range[1]);

  const scheduleRule = calculateDays(currentCalendar.rule, start, end);

  if (!scheduleRule) {
    return null;
  }

  const availableDays = scheduleRule
    .occurrences()
    .toArray()
    .map(({ date }) => date.toString());

  const today = new Date(new Date().setUTCHours(0, 0, 0, 0));

  const allDays =
    start &&
    end &&
    Math.round((end.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1;

  const validDays = availableDays?.length;

  let passedDays = Math.round(
    (new Date(today).getTime() - start.getTime()) / (1000 * 3600 * 24)
  );
  if (passedDays > allDays) {
    passedDays = allDays;
  } else if (passedDays < 0) {
    passedDays = 0;
  }

  let crossedDays = 0;
  for (const year in currentCalendar.selected) {
    const selectedYear = currentCalendar.selected[year];
    for (const month in selectedYear) {
      const selectedMonth = selectedYear[month];
      for (let day = 0; day < selectedMonth.length; day++) {
        const dateObj = new Date(
          parseInt(year),
          parseInt(month),
          selectedMonth[day]
        );

        if (availableDays?.includes(dateObj.toString())) {
          crossedDays++;
        }
      }
    }
  }

  const remainingDays = allDays - passedDays;

  return (
    <div className="progress-container">
      <div style={{ fontWeight: "500", marginBottom: "0.5rem" }}>Progress</div>
      <div className="progress-wrapper">
        <div className="progress-title">
          <div>Passed</div>
          <div>Remaining</div>
        </div>
        <div className="progress-section">
          {passedDays}
          <LinearProgress
            variant="determinate"
            value={(passedDays / allDays) * 100}
            className="progress-bar"
          />
          {remainingDays}
        </div>
        <div className="divider"></div>
        <div className="progress-title">
          <div>Crossed</div>
          <div>Uncrossed</div>
        </div>
        <div className="progress-section">
          {crossedDays}
          <LinearProgress
            variant="determinate"
            value={(crossedDays / validDays) * 100}
            className="progress-bar"
            color="secondary"
          />
          {validDays - crossedDays}
        </div>
        <div className="divider"></div>
        <div className="progress-title">
          <div>Crossable</div>
          <div>All</div>
        </div>
        <div className="progress-section">
          {validDays}
          <LinearProgress
            variant="determinate"
            value={(validDays / allDays) * 100}
            className="progress-bar"
            color="info"
          />
          {allDays}
        </div>
      </div>
    </div>
  );
};

export default Progress;
