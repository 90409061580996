import { Button } from "@mui/material";

import "../css/Prompt.css";
import { useAuth } from "../infrastructure/AuthContext";
import { useCalendars } from "../infrastructure/CalendarContext";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase/fbConfig";

interface PromptProps {
  setShow: (state: boolean) => void;
}

const Prompt = ({ setShow }: PromptProps) => {
  const {
    updateUserData,
    switchToCalendar,
    currentCalendar,
    order,
    activeTabId,
  } = useCalendars();
  const { user } = useAuth();

  const handleRemove = () => {
    if (!user || !activeTabId) {
      return;
    }

    const userDoc = doc(firestore, "users", user.uid);
    const calendarsDoc = doc(
      firestore,
      "users",
      user.uid,
      "calendars",
      activeTabId
    );
    Promise.all([
      updateDoc(userDoc, {
        calendarsOrder: order.filter((id) => id !== activeTabId),
      }),
      deleteDoc(calendarsDoc),
    ]).then(
      () =>
        updateUserData &&
        updateUserData().then((id) => id && switchToCalendar(id))
    );
  };

  return (
    <div className="prompt-container">
      <div className="prompt">
        <div>Delete "{currentCalendar?.name}"</div>
        <div className="prompt-content">
          <Button
            color={"error"}
            style={{ width: "100%" }}
            onClick={() => handleRemove()}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            style={{ width: "100%" }}
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Prompt;
