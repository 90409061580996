import { Schedule } from "./rschedule";

import { goalTemplate } from "./dataStructures";

export const calculateDays = (rule, startDate, endDate) => {
  const rscheduleWeekdays = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  let baseRule = {
    start: startDate,
    end: endDate,
    interval: rule.interval,
  };

  switch (rule.frequency) {
    case 0: {
      return new Schedule({
        rrules: [{ ...baseRule, frequency: "DAILY" }],
      });
    }
    case 1: {
      return new Schedule({
        rrules: [
          {
            ...baseRule,
            frequency: "WEEKLY",
            byDayOfWeek: rule.weekPattern.map(
              (dayNumber) => rscheduleWeekdays[dayNumber]
            ),
          },
        ],
      });
    }
    case 2: {
      return new Schedule({
        rrules: [
          {
            ...baseRule,
            frequency: "MONTHLY",
            byDayOfMonth: rule.monthPattern,
          },
        ],
      });
    }
    default:
      return;
  }
};

export const updateStructure = (userGoals) => {
  let updatedUserGoals = [];

  for (let i = 0; i < userGoals.length; i++) {
    const oldStructure = userGoals[i];
    let tempStructure = JSON.parse(JSON.stringify(oldStructure));

    if (tempStructure.pattern) {
      tempStructure = {
        ...tempStructure,
        rule: { ...goalTemplate.rule, weekPattern: tempStructure.pattern },
      };
      delete tempStructure.pattern;
    }

    if (!tempStructure.rule) {
      tempStructure = { ...tempStructure, rule: goalTemplate.rule };
    }

    updatedUserGoals.push(tempStructure);
  }

  return updatedUserGoals;
};
